// Libs
import React, { useState, useEffect, useContext } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import flatten from 'lodash.flatten';
import styled, { css } from 'styled-components';

// Components
import Layout from 'components/layout';
import { device } from 'components/device';
import SEO from 'components/seo';
import TextSection from 'components/units/unitPages/details/TextSection';
import PromptServiceContent from 'components/promptService/promptServiceContent';
import ListPosts from 'components/blog/listPosts';

import Qa from 'components/Qa';
import { FeaturedMarketing } from 'components/CmsComponents/FeaturedMarketing';
import Components from 'components/CmsComponents/';
import { HeaderContext } from 'context/header';

// Styles
const GridContainer = styled.div`
  display: ${props => (props.isDesktop ? 'grid' : 'flex')};
  grid-template-columns: ${props =>
    props.isDesktop && 'auto repeat(12, 70px) auto'};
  column-gap: ${props => props.isDesktop && '30px'};
  flex-direction: ${props => !props.isDesktop && 'column'};
  padding: ${props => !props.isDesktop && '20px'};

  @media ${device.laptop} {
    flex-direction: column;
    padding-bottom: 0;
    padding-top: 0;
    margin-top: 0;
  }
`;

const Part = styled.div`
  display: ${props => props.isDisplay};
  grid-column: ${props => props.gridColumn};
  border-bottom: ${props => props.borderBottom && '1px solid #C6C8CC'};
  padding-top: ${props => props.paddingTop && '70px'};

  section :first-child {
    padding: ${props =>
      props.notPadding
        ? '70px 0 0 !important'
        : props.notPaddingTop && '0 0 70px !important'};
    p {
      padding-left: ${props => props.notLeft && '0'};
    }

    @media ${device.laptop} {
      width: ${props => props.notPaddingTop && '100%'};
      padding: ${props =>
        props.notPadding
          ? '30px 0 0 !important'
          : props.notPaddingTop && '30px 0 !important'};
      margin: 0;
      border-bottom: ${props => props.notPaddingTop && 'unset'};
    }

    @media ${device.tablet} {
      padding: ${props => props.notPaddingTop && '0 0 30px !important'};
    }
  }

  section :last-child {
    padding: ${props => props.shortPadding && '20px 0 70px'};
    @media ${device.laptop} {
      padding: ${props => props.shortPadding && '0 0 30px'};
    }
  }

  section {
    div {
      div {
        h2 {
          width: ${props => props.largeTitle && '28rem'};

          @media ${device.laptop} {
            width: 100%;
          }
        }
      }
    }
  }

  :first-child {
    display: flex;
    justify-content: space-between;
    align-items: ${props => (props.notFirstChild ? 'unset' : 'center')};
    padding: ${props => props.isPaddingTop && '70px 0 70px'};

    @media ${device.laptop} {
      margin-bottom: 0;
      padding: ${props => props.isPaddingTop && '0 0 1.875rem'};
    }

    @media ${device.tablet} {
      margin-bottom: ${props => (props.isCDIBelaVista ? '0' : '1.875rem')};
      padding: ${props => props.isPaddingTop && '1.875rem 0 1.875rem'};
    }

    @media ${device.mobile} {
      margin-bottom: 0;
      padding: ${props => props.isPaddingTop && '0 0 1.875rem'};
    }
  }

  :last-child {
    margin-bottom: 0;
  }

  @media ${device.laptop} {
    flex-direction: column;
    margin-top: 0;
    margin: ${props => props.isMarginFullWidth && '0 -1.25rem'};
    padding-top: ${props => props.paddingTop && '30px'};
    padding-bottom: ${props => props.notPaddingTop && '30px'};
    border-bottom: ${props =>
      props.borderPersonalize
        ? '1px solid #C6C8CC'
        : props.noBorderLap
        ? 'unset'
        : props.notPaddingTop && '1px solid #C6C8CC'};
    border-top: ${props => props.paddingTop && '1px solid #C6C8CC'};
    section {
      div {
        display: ${props => props.paddingTop && 'flex'};
      }
    }
  }

  @media ${device.tablet} {
    padding-top: ${props => props.paddingTop && '0'};
    border-top: ${props => props.paddingTop && 'unset'};
  }

  ${props =>
    props.padding &&
    css`
      padding: 4.375rem 0;

      @media ${device.laptop} {
        padding: 1.875rem 0;
      }
    `}

  ${props =>
    props.borderTop &&
    css`
      border-top: 1px solid #c6c8cc;
    `}
`;

const getMarkdown = (page, fragmentId, isMarkdown = false, assets = false) => {
  const { fragments } = page[0];
  const findFragment = fragments.find(item => item.id === fragmentId);

  if (isMarkdown) {
    const { markdown } = flatten(findFragment.localizations)[0];
    return markdown;
  } else if (assets) {
    return findFragment.assetpicker;
  } else {
    return flatten(findFragment.localizations);
  }
};

const renderPage = (page, isDesktop, pageContext) => {
  const featuredMarketingContent = page[0]?.featuredMarketing;
  const components = page[0]?.components ?? [];
  const {
    setMainMarginTopLaptop,
    setMainMarginTopMobile,
    showAlertPostFeriado,
    isVisibleNovoSite,
  } = useContext(HeaderContext);

  useEffect(() => {
    if (showAlertPostFeriado || isVisibleNovoSite) {
      setMainMarginTopLaptop('9.075rem');
      setMainMarginTopMobile('7.875rem');
    } else {
      setMainMarginTopLaptop('7.063rem');
      setMainMarginTopMobile('5.875rem');
    }
  }, [showAlertPostFeriado, isVisibleNovoSite]);
  return (
    <>
      {featuredMarketingContent && (
        <FeaturedMarketing content={featuredMarketingContent} />
      )}
      <GridContainer isDesktop={isDesktop}>
        <Part
          gridColumn="2 / -2"
          width="100%"
          isDisplay="flex"
          notPaddingTop
          noBorderLap
          borderBottom
          isPaddingTop
          notFirstChild
        >
          <TextSection
            markdown={getMarkdown(page, 'ckpejmfhsa3qi0c254et74ait', true)}
            isAbout
            isShowing
            isCDICardio
            isPatologica
            isBackground
            isOthersUnits
            containerWidth="50%"
            noMarginRightPage
            padding="0"
          />
          <TextSection
            markdown={getMarkdown(page, 'ckovseawg137b0b70g5am3s07', true)}
            fragments={getMarkdown(page, 'ckovseawg137b0b70g5am3s07')}
            isAbout
            isShowing
            isPatologica
            marginLeftContainerMobile="3%"
            containerColorEmDesk="#231f20"
            isAddressAndTimes
            isIncreasingContainerWidth
            containerWidth="49%"
          />
        </Part>
        <Part
          gridColumn={'2 / -2'}
          largeTitle
          notPadding
          notLeft
          // shortPadding
          borderBottom
        >
          <PromptServiceContent
            markdown={getMarkdown(page, 'ckovsinjc140u0b672a142sk1', true)}
            fragments={getMarkdown(page, 'ckovsinjc140u0b672a142sk1')}
            // padding
            notTitleBold
            facilitiesForYou
            isBottom
            prontoAtendimento
            isFacilities
            isPatologica
            containerPaddingLap="2.375rem 0"
          />
          <PromptServiceContent
            markdown={getMarkdown(page, 'ckpejq5r4a7jv0b769tekmddp', true)}
            fragments={getMarkdown(page, 'ckpejq5r4a7jv0b769tekmddp')}
            // padding
            notTitleBold
            facilitiesForYou
            isDropdownDesktop
            isPatologica
            containerPaddingDesk="3.125rem 0 4.375rem"
            contentFontSizeMob="0.875rem"
            containerPaddingLap
            containerPaddingLap="2.375rem 0"
            breakLineBr
          />
        </Part>
        <Part gridColumn={'2 / -2'} largeTitle notLeft borderBottom>
          <PromptServiceContent
            markdown={getMarkdown(page, 'ckpejsh34a63l0c2566vhbxu5', true)}
            isShowing
            containerBorderTopLap="1px solid #C6C8CC"
            containerWidth="50%"
            containerPaddingDesk="4.375rem 0"
            containerWidthLap="100%"
            notTitleBold
            isBottom
            prontoAtendimento
            containerPaddingLap="2.375rem 0"
          />
        </Part>
        <Part gridColumn={'2 / -2'} largeTitle notLeft>
          <PromptServiceContent
            markdown={getMarkdown(page, 'ckpejtv8oa8570b76qc6i9l2d', true)}
            isShowing
            containerBorderTopLap="1px solid #C6C8CC"
            containerWidth="50%"
            containerPaddingDesk="4.375rem 0"
            containerWidthLap="100%"
            notTitleBold
            isBottom
            prontoAtendimento
            containerPaddingLap="2.375rem 0"
          />
        </Part>
        <Part gridColumn={'1 / -1'} isMarginFullWidth>
          {components.length >= 1 &&
            components?.map(component => {
              return Components(component);
            })}
        </Part>
        {page?.[0]?.qa && (
          <Part gridColumn={'2 / -2'} borderBottom borderPersonalize>
            <Qa
              title={'Perguntas Frequentes'}
              data={page?.[0]?.qa}
              noBorderBottomLastElement={page?.[0]?.posts.length > 0}
            />
          </Part>
        )}
        {page?.[0]?.posts?.length > 0 && (
          <Part gridColumn={'2 / -2'} padding borderTop>
            <ListPosts
              title="Blog do Sírio-Libanês"
              postsToShow={3}
              posts={page?.[0]?.posts}
              headerLink={`/${page[0]?.customURLBlog ?? 'blog/'}`}
            />
          </Part>
        )}
      </GridContainer>
    </>
  );
};

const Bucomaxilofacial = ({ page, pageContext }) => {
  const [isDesktop, setDesktop] = useState(true);

  const updateMedia = () => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 1024);
    }
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', updateMedia);
      return () => window.removeEventListener('resize', updateMedia);
    }
  });

  return (
    <Layout>
      <SEO dataSeo={page} />
      {renderPage(page, isDesktop, pageContext)}
    </Layout>
  );
};

export default props => {
  return (
    <StaticQuery
      query={graphql`
        query {
          gcms {
            site(where: { id: "ckgi8dg9k08b10a74cf20cxgk" }) {
              pages(where: { id: "ckpejvu88a9ux0c25reoiqfku" }) {
                featuredMarketing(locales: [pt_BR, en]) {
                  id
                  type
                  breadcrumb
                  backgroundColor {
                    hex
                  }
                  images {
                    handle
                    width
                    height
                    url
                  }
                  title
                  fragments {
                    id
                    name
                    type
                    markdown
                  }
                }
                components(locales: [pt_BR, en]) {
                  __typename
                  ... on GraphCMS_HighlightContent {
                    id
                    name
                    type
                    backgroundColorInitial {
                      hex
                    }
                    backgroundColorFinal {
                      hex
                    }
                    title
                    customTitle
                    description
                    images {
                      handle
                      width
                      height
                      url
                    }
                    fragments {
                      id
                      name
                      type
                      markdown
                    }
                  }
                  ... on GraphCMS_DoctorAndSpecialtyContent {
                    id
                    name
                    type
                    title
                    subtitle
                    estado
                    images {
                      handle
                      width
                      height
                      url
                    }
                    specialties {
                      id
                      name
                      slug
                      customUrl
                      unity
                    }
                    fragments {
                      id
                      name
                      type
                      markdown
                    }
                  }
                }
                qa(locales: pt_BR) {
                  titulo
                  pergunta
                  autor
                  doctors(first: 1000) {
                    active
                    assignment
                    name
                  }
                }
                fragments {
                  id
                  name
                  localizations {
                    singletexts
                    markdown
                  }
                }
                seo {
                  metaTitle
                  metaDescription
                  noIndex
                  localizations {
                    metaTitle
                    metaDescription
                    noIndex
                  }
                }
                customURLBlog
                posts {
                  id
                  slugPost
                  title
                  topic
                  author
                  doctors(first: 1000, locales: [pt_BR, en]) {
                    id
                    id_api
                    active
                    assignment
                    name
                  }
                  assetpicker {
                    handle
                    height
                    width
                  }
                  categories {
                    tag
                  }
                }
              }
            }
          }
        }
      `}
      render={response => {
        return <Bucomaxilofacial page={response.gcms.site.pages} {...props} />;
      }}
    />
  );
};
